import { createGlobalStyle } from 'styled-components'
import {
  customColorLogicSites,
  merriweatherFontSites,
} from '~/config/JPIMConfig'
import { encodedExternalLinkIconBlack } from '~/icons/externalLinkIcon'
import { convertHexToRGBA } from '~/util/convertHexColor'
import { jpiFonts, merriweatherFonts } from './fonts'
import { generateColorVariation } from '~/util/generateColorVariation'

const deepCopy = (data) => JSON.parse(JSON.stringify(data))

const MOBILE_SMALL_BREAKPOINT = 320
const MOBILE_MEDIUM_BREAKPOINT = 375
const MOBILE_LARGE_BREAKPOINT = 425
const TABLET_SMALL_BREAKPOINT = 530
const TABLET_MEDIUM_BREAKPOINT = 580
export const TABLET_BREAKPOINT = 768
export const DESKTOP_ADS_BREAKPOINT = 970
const LAPTOP_SMALL_BREAKPOINT = 1220
export const LAPTOP_MEDIUM_BREAKPOINT = 1340
const LAPTOP_LARGE_BREAKPOINT = 1440
export const LAPTOP_BREAKPOINT = 1024
export const MAX_LAPTOP_BREAKPOINT = LAPTOP_BREAKPOINT - 1
const DESKTOP_BREAKPOINT = 2560
export const INSKIN_BREAKPOINT = 1260

const theme = {
  unstyled: {
    primaryColor: '#232323',
    primaryColorLight: convertHexToRGBA('#232323', 16),
    secondaryColor: '#000',
    lightContrast: '#ffffff',
    lightGrey: '#cccccc',
    lightGreyContrast: '#f5f5f5',
    greyContrast: '#ececec',
    darkContrast: '#222222',
    darkGreyContrast: '#202020',
    darkestContrast: '#000000',
    pinkContrast: '#FFF2F4',
    hoverColor: '#D0021B',
    alertColor: '#CE1419',
    breakingNewsGrey: '#d8d8d8',
    breakingNewsYellow: '#fde73e',
    adContainerBottomBorder: '#ececec',
    adContainerGrey: '#f7f7f7',
    contributorLight: 'rgb(60 110 244 / 60%)',
    fontColor: '#222',
    fontColorMedium: '#6C6C6C',
    redContrast: '#d0021c',
    orangeContrast: '#f8893e',
    fontColorLight: '#A5A5A5',
    fontFamily: {
      primaryFont: "'Overpass', 'Adjusted Overpass Fallback', sans-serif",
      secondaryFont: "'Overpass', 'Adjusted Overpass Fallback', sans-serif",
      stagSerif: "'Stag', serif",
      openSans: "'OpenSans', 'Adjusted OpenSans Fallback', sans-serif",
      openSansSemiBold:
        "'OpenSans-Semibold', 'Adjusted OpenSans-Semibold Fallback', sans-serif",
    },
    facebook: '#1877f2', // Facebook Blue Pantone 2727 C
    linkedin: '#0077b5', // LinkedIn Blue PMS 641
    pinterest: '#d6001c', // Pinterest Red PMS 2035 C
    whatsapp: '#25d366',
  },
  'nationalworld.com': {
    primaryColor: '#396bf3',
    primaryColorLight: convertHexToRGBA('#396bf3', 16),
    secondaryColor: '#222',
    lightContrast: '#ffffff',
    lightGrey: '#cccccc',
    lightGreyContrast: '#f5f5f5',
    greyContrast: '#ececec',
    pinkContrast: '#FFF2F4',
    darkContrast: '#222222',
    darkGreyContrast: '#202020',
    mediumGreyContrast: '#e7e7e7',
    darkestContrast: '#000000',
    hoverColor: '#000000',
    alertColor: '#CE1419',
    breakingNewsGrey: '#d8d8d8',
    breakingNewsYellow: '#fde73e',
    adContainerBottomBorder: '#ececec',
    adContainerGrey: '#f7f7f7',
    contributorLight: 'rgb(60 110 244 / 60%)',
    fontColor: '#222',
    fontColorMedium: '#6C6C6C',
    fontColorLight: '#A5A5A5',
    backgroundColor: '#fff',
    redContrast: '#d0021c',
    orangeContrast: '#f8893e',
    fontFamily: {
      primaryFont: "'Merriweather', 'Adjusted Merriweather Fallback', serif",
      secondaryFont: "'Merriweather', 'Adjusted Merriweather Fallback', serif",
      componentFont:
        "'Merriweather-Bold', 'Adjusted Merriweather-Bold Fallback', serif",
      titleFont:
        "'Merriweather-Bold', 'Adjusted Merriweather-Bold Fallback', serif",
      openSans: "'OpenSans','Adjusted OpenSans Fallback', sans-serif",
      openSansSemiBold:
        "'OpenSans-Semibold','Adjusted OpenSans-Semibold Fallback', sans-serif",
    },
    primaryColorSections: {
      culture: '#d81b79',
      royals: '#721f9a',
      sport: '#24a271',
      travel: '#1b7f98',
    },
    facebook: '#1877f2', // Facebook Blue Pantone 2727 C
    linkedIn: '#1877f2', // LinkedIn Blue PMS 641
    pinterest: '#d6001c', // Pinterest Red PMS 2035 C
    whatsapp: '#25d366',
  },
  'thestar.co.uk': {
    primaryColor: '#2D3091',
    primaryColorLight: convertHexToRGBA('#2D3091', 16),
    secondaryColor: '#6163DE',
    backgroundColor: '#fff',
    lightContrast: '#ffffff',
    lightGrey: '#cccccc',
    lightGreyContrast: '#f5f5f5',
    greyContrast: '#ececec',
    darkContrast: '#222222',
    darkGreyContrast: '#202020',
    darkestContrast: '#000000',
    pinkContrast: '#FFF2F4',
    hoverColor: '#D0021B',
    alertColor: '#CE1419',
    breakingNewsGrey: '#d8d8d8',
    breakingNewsYellow: '#fde73e',
    adContainerBottomBorder: '#ececec',
    adContainerGrey: '#f7f7f7',
    contributorLight: 'rgb(60 110 244 / 60%)',
    fontColor: '#222',
    fontColorMedium: '#6C6C6C',
    fontColorLight: '#A5A5A5',
    redContrast: '#d0021c',
    orangeContrast: '#f8893e',
    fontFamily: {
      primaryFont: "'Merriweather', 'Adjusted Merriweather Fallback', serif",
      secondaryFont: "'Merriweather', 'Adjusted Merriweather Fallback', serif",
      componentFont:
        "'Merriweather-Bold', 'Adjusted Merriweather-Bold Fallback', serif",
      titleFont:
        "'Merriweather-Bold', 'Adjusted Merriweather-Bold Fallback', serif",
      openSans: "'OpenSans','Adjusted OpenSans Fallback', sans-serif",
      openSansSemiBold:
        "'OpenSans-Semibold','Adjusted OpenSans-Semibold Fallback', sans-serif",
    },
    facebook: '#1877f2', // Facebook Blue Pantone 2727 C
    linkedin: '#0077b5', // LinkedIn Blue PMS 641
    pinterest: '#d6001c', // Pinterest Red PMS 2035 C
    whatsapp: '#25d366',
  },
  'thestar-legacy-theme': {
    primaryColor: '#2D3091',
    primaryColorLight: convertHexToRGBA('#2D3091', 16),
    secondaryColor: '#6163DE',
    lightContrast: '#ffffff',
    lightGrey: '#cccccc',
    lightGreyContrast: '#f5f5f5',
    greyContrast: '#ececec',
    darkContrast: '#222222',
    darkGreyContrast: '#202020',
    darkestContrast: '#000000',
    pinkContrast: '#FFF2F4',
    hoverColor: '#D0021B',
    alertColor: '#CE1419',
    breakingNewsGrey: '#d8d8d8',
    breakingNewsYellow: '#fde73e',
    adContainerBottomBorder: '#ececec',
    adContainerGrey: '#f7f7f7',
    contributorLight: 'rgb(60 110 244 / 60%)',
    fontColor: '#222',
    fontColorMedium: '#6C6C6C',
    fontColorLight: '#A5A5A5',
    redContrast: '#d0021c',
    orangeContrast: '#f8893e',
    fontFamily: {
      primaryFont: "'Overpass', 'Adjusted Overpass Fallback', sans-serif",
      secondaryFont: "'Overpass', 'Adjusted Overpass Fallback', sans-serif",
      stagSerif: "'Stag', serif",
      openSans: "'OpenSans', 'Adjusted OpenSans Fallback', sans-serif",
      openSansSemiBold:
        "'OpenSans-Semibold', 'Adjusted OpenSans-SemiBold Fallback', sans-serif",
    },
    facebook: '#1877f2', // Facebook Blue Pantone 2727 C
    linkedin: '#0077b5', // LinkedIn Blue PMS 641
    pinterest: '#d6001c', // Pinterest Red PMS 2035 C
    whatsapp: '#25d366',
  },
  'scotsman.com': {
    primaryColor: '#222222',
    primaryColorLight: convertHexToRGBA('#1BB2BB', 16),
    secondaryColor: '#222',
    lightContrast: '#ffffff',
    lightGrey: '#cccccc',
    lightGreyContrast: '#f5f5f5',
    greyContrast: '#ececec',
    pinkContrast: '#FFF2F4',
    darkContrast: '#222222',
    darkGreyContrast: '#202020',
    mediumGreyContrast: '#e7e7e7',
    darkestContrast: '#000000',
    hoverColor: '#1BB2BB',
    alertColor: '#CE1419',
    breakingNewsGrey: '#d8d8d8',
    breakingNewsYellow: '#fde73e',
    adContainerBottomBorder: '#ececec',
    adContainerGrey: '#f7f7f7',
    contributorLight: 'rgb(60 110 244 / 60%)',
    fontColor: '#222',
    fontColorMedium: '#6C6C6C',
    fontColorLight: '#A5A5A5',
    backgroundColor: '#fff',
    redContrast: '#d0021c',
    orangeContrast: '#f8893e',
    fontFamily: {
      primaryFont: "'Merriweather', 'Adjusted Merriweather Fallback', serif",
      secondaryFont: "'Merriweather', 'Adjusted Merriweather Fallback', serif",
      componentFont:
        "'Merriweather-Bold', 'Adjusted Merriweather-Bold Fallback', serif",
      titleFont:
        "'Merriweather-Bold', 'Adjusted Merriweather-Bold Fallback', serif",
      openSans: "'OpenSans','Adjusted OpenSans Fallback', sans-serif",
      openSansSemiBold:
        "'OpenSans-Semibold','Adjusted OpenSans-Semibold Fallback', sans-serif",
      gabarito: "'Gabarito', sans-serif",
    },
    primaryColorSections: {
      news: '#e20026',
      readthis: '#e20026',
      health: '#e20026',
      sport: '#002857',
      business: '#7aa652',
      lifestyle: '#ec7404',
      whatson: '#ec7404',
      artsandculture: '#ec7404',
      farmweek: '#ce073f',
      horseweek: '#49914c',
      equestrian: '#49914c',
    },
    facebook: '#1877f2', // Facebook Blue Pantone 2727 C
    linkedIn: '#1877f2', // LinkedIn Blue PMS 641
    pinterest: '#d6001c', // Pinterest Red PMS 2035 C
    whatsapp: '#25d366',
  },
  'yorkshirepost.co.uk': {
    primaryColor: '#222222',
    primaryColorLight: convertHexToRGBA('#222', 16),
    secondaryColor: '#FFC600',
    lightContrast: '#ffffff',
    lightGrey: '#cccccc',
    lightGreyContrast: '#f5f5f5',
    greyContrast: '#ececec',
    darkContrast: '#222222',
    pinkContrast: '#FFF2F4',
    darkGreyContrast: '#202020',
    darkestContrast: '#000000',
    hoverColor: '#FFC600',
    alertColor: '#CE1419',
    breakingNewsGrey: '#d8d8d8',
    breakingNewsYellow: '#fde73e',
    adContainerBottomBorder: '#ececec',
    adContainerGrey: '#f7f7f7',
    contributorLight: 'rgb(60 110 244 / 60%)',
    fontColor: '#222',
    fontColorMedium: '#6C6C6C',
    fontColorLight: '#A5A5A5',
    backgroundColor: '#fff',
    redContrast: '#d0021c',
    orangeContrast: '#f8893e',
    fontFamily: {
      primaryFont: "'Merriweather', 'Adjusted Merriweather Fallback', serif",
      secondaryFont: "'Merriweather', 'Adjusted Merriweather Fallback', serif",
      componentFont:
        "'Merriweather-Bold', 'Adjusted Merriweather-Bold Fallback', serif",
      titleFont:
        "'Merriweather-Bold', 'Adjusted Merriweather-Bold Fallback', serif",
      openSans: "'OpenSans', 'Adjusted OpenSans Fallback', sans-serif",
      openSansSemiBold:
        "'OpenSans-Semibold', 'Adjusted OpenSans-Semibold Fallback', sans-serif",
    },
    facebook: '#1877f2', // Facebook Blue Pantone 2727 C
    linkedin: '#0077b5', // LinkedIn Blue PMS 641
    pinterest: '#d6001c', // Pinterest Red PMS 2035 C
    whatsapp: '#25d366',
  },
}

const extraPublications = [
  ['ballymenatimes.com', '#c80c0f'],
  ['buteman.co.uk', '#002f63'],
  ['cumbernauld-news.co.uk', '#002f63'],
  ['leighobserver.co.uk', '#0075be'],
  ['leylandguardian.co.uk', '#00569d'],
  ['longridgenews.co.uk', '#e00c18'],
  ['morleyobserver.co.uk', '#bf2d16'],
  ['northyorkshirenews.com', '#ee402f'],
  ['retfordtoday.co.uk', '#009ee0'],
]

const extraNationalPublications = [
  ['3addedminutes.com', '#19c67d', '#222'],
  ['anguscountyworld.co.uk', '#333399', '#222'],
  ['banburyguardian.co.uk', '#3a96bd', '#222'],
  ['bedfordtoday.co.uk', '#0069b3', '#222'],
  ['biggleswadetoday.co.uk', '#0069b4', '#222'],
  ['birminghamworld.uk', '#4361ee'],
  ['blackpoolgazette.co.uk', '#da0e0e', '#222'],
  ['bristolworld.com', '#d90429'],
  ['bucksherald.co.uk', '#bf2d16', '#222'],
  ['burnleyexpress.net', '#c32e4a', '#222'],
  ['buxtonadvertiser.co.uk', '#e20613', '#222'],
  ['chad.co.uk', '#009353'],
  ['daventryexpress.co.uk', '#0075be'],
  ['derbyshiretimes.co.uk', '#00adee'],
  ['derbyworld.co.uk', '#222', '#9932cc'],
  ['derryjournal.com', '#002f63'],
  ['dewsburyreporter.co.uk', '#a62949'],
  ['doncasterfreepress.co.uk', '#0b77b7'],
  ['edinburghnews.scotsman.com', '#073773', '#222'],
  ['falkirkherald.co.uk', '#0287c1'],
  ['farminglife.com', '#006562'],
  ['fifetoday.co.uk', '#002f63'],
  ['glasgowworld.com', '#7f24c5'],
  ['halifaxcourier.co.uk', '#a62949'],
  ['harboroughmail.co.uk', '#bf2616'],
  ['harrogateadvertiser.co.uk', '#231e5c'],
  ['hartlepoolmail.co.uk', '#382f87'],
  ['hemeltoday.co.uk', '#288235'],
  ['hucknalldispatch.co.uk', '#008D5C'],
  ['lancasterguardian.co.uk', '#1ba196'],
  ['leightonbuzzardonline.co.uk', '#e20613'],
  ['lep.co.uk', '#005da3'],
  ['lincolnshireworld.com', '#336131', '#000'],
  ['liverpoolworld.uk', '#222', '#ff0000'],
  ['londonworld.com', '#990066'],
  ['lutontoday.co.uk', '#db210a'],
  ['manchesterworld.uk', '#222', '#fff400'],
  ['meltontimes.co.uk', '#004da9'],
  ['miltonkeynes.co.uk', '#cd1719'],
  ['newcastleworld.com', '#222', '#fff400'],
  ['newryreporter.com', '#222', '#E30615'],
  ['newsletter.co.uk', '#282c71'],
  ['northamptonchron.co.uk', '#d22027'],
  ['northantstelegraph.co.uk', '#0066b2'],
  ['northernirelandworld.com', '#222', '#669999'],
  ['northumberlandgazette.co.uk', '#00638d'],
  ['nottinghamworld.com', '#11B488', '#222'],
  ['peterboroughtoday.co.uk', '#0094D9'],
  ['portsmouth.co.uk', '#0C418C'],
  ['rotherhamadvertiser.co.uk', '#222', '#E30615'],
  ['shieldsgazette.com', '#0069b3'],
  ['stornowaygazette.co.uk', '#002856'],
  ['sunderlandecho.com', '#ed1c24', '#d0021b'],
  ['surreyworld.co.uk', '#4361EE', '#222'],
  ['sussexexpress.co.uk', '#4361ee'],
  ['thescarboroughnews.co.uk', '#00638c'],
  ['thesouthernreporter.co.uk', '#2e3191'],
  ['totallysnookered.com', '#014500', '#222'],
  ['wakefieldexpress.co.uk', '#005a9c'],
  ['walesworld.com', '#008000', '#000'],
  ['warwickshireworld.com', '#222', '#ff0000'],
  ['wigantoday.net', '#A10D1D'],
  ['worksopguardian.co.uk', '#009ee2'],
  ['yorkshireeveningpost.co.uk', '#3A2E94', '#222'],
]

extraPublications.forEach(([domain, primaryColor]) => {
  theme[domain] = deepCopy(theme['thestar-legacy-theme'])
  theme[domain]['primaryColor'] = primaryColor
  theme[domain]['primaryColorLight'] = convertHexToRGBA(
    theme[domain]['primaryColor'],
    16,
  )
})

extraNationalPublications.forEach(([domain, primaryColor, hoverColor]) => {
  theme[domain] = deepCopy(theme['scotsman.com'])
  theme[domain]['primaryColor'] = primaryColor
  theme[domain]['primaryColorLight'] = convertHexToRGBA(
    theme[domain]['primaryColor'],
    16,
  )
  theme[domain]['hoverColor'] = hoverColor
})

const device = {
  mobileS: `(min-width: ${MOBILE_SMALL_BREAKPOINT}px)`,
  maxMobileS: `(max-width: ${MOBILE_SMALL_BREAKPOINT - 1}px)`,
  mobileM: `(min-width: ${MOBILE_MEDIUM_BREAKPOINT}px)`,
  maxMobileM: `(max-width: ${MOBILE_MEDIUM_BREAKPOINT - 1}px)`,
  mobileL: `(min-width: ${MOBILE_LARGE_BREAKPOINT}px)`,
  maxMobileL: `(max-width: ${MOBILE_LARGE_BREAKPOINT - 1}px)`,
  maxTablet: `(max-width: ${TABLET_BREAKPOINT - 1}px)`,
  maxTabletS: `(max-width: ${TABLET_SMALL_BREAKPOINT - 1}px)`,
  tabletS: `(min-width: ${TABLET_SMALL_BREAKPOINT}px)`,
  tabletM: `(min-width: ${TABLET_MEDIUM_BREAKPOINT}px)`,
  tablet: `(min-width: ${TABLET_BREAKPOINT}px)`,
  desktopAds: `(min-width: ${DESKTOP_ADS_BREAKPOINT}px)`,
  laptopS: `(min-width: ${LAPTOP_SMALL_BREAKPOINT}px)`,
  laptopM: `(min-width: ${LAPTOP_MEDIUM_BREAKPOINT}px)`,
  laptop: `(min-width: ${LAPTOP_BREAKPOINT}px)`,
  maxLaptopM: `(max-width: ${LAPTOP_MEDIUM_BREAKPOINT - 1}px)`,
  laptopL: `(min-width: ${LAPTOP_LARGE_BREAKPOINT}px)`,
  desktop: `(min-width: ${DESKTOP_BREAKPOINT}px)`,
  maxLaptop: `(max-width: ${LAPTOP_BREAKPOINT - 1}px)`,
}

const fontStyleProvider = ({ theme }) => {
  const { domain } = theme
  return merriweatherFontSites.includes(domain) ? merriweatherFonts : jpiFonts
}

const googleExtendedAccessOverlay = `
  html.overlay-gea {
    overflow-x: inherit!important;
    body {
      overflow: hidden!important;
      &:before {
        content: '';
        z-index: 1000;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.6;
      }
    }
  }


`

const sectionPrimaryColor = (currentTheme, hierarchyName) => {
  const { domain, primaryColor, primaryColorSections } = currentTheme
  const hierarchyNameParsed = hierarchyName
    ?.toLowerCase()
    .replace(/[^a-z0-9]/g, '')
  const sectionColor = !customColorLogicSites.includes(domain)
    ? primaryColor
    : primaryColorSections?.[hierarchyNameParsed] || primaryColor
  return sectionColor
}
const addColorVariations = (themeObject) => {
  Object.keys(themeObject).forEach((key) => {
    if (typeof themeObject[key] === 'object' && themeObject[key].primaryColor) {
      themeObject[key].colorVariations = generateColorVariation(
        themeObject[key].primaryColor,
      )
    }
    if (themeObject[key].secondaryColor) {
      themeObject[key].secondaryColorVariations = generateColorVariation(
        themeObject[key].secondaryColor,
      )
    }
    if (themeObject[key].primaryColorSections) {
      const primaryColorSections = themeObject[key].primaryColorSections
      const primaryColorSectionsVariations = {}
      Object.keys(primaryColorSections).forEach((section) => {
        let sectionColor = primaryColorSections[section]

        if (typeof sectionColor === 'string') {
          sectionColor = { primaryColor: sectionColor }
        }

        primaryColorSectionsVariations[section] = {
          ...sectionColor,
          ...generateColorVariation(sectionColor.primaryColor),
        }
      })
      themeObject[key].primaryColorSections = primaryColorSectionsVariations
    }
  })
}

addColorVariations(theme, extraNationalPublications)

const GlobalStyles = createGlobalStyle`
  :root {
    --nw-breaking-news: #fde73e;
    --primary-500: ${({ theme }) => theme.primaryColor};
    --primary-300: ${({ theme }) => theme.colorVariations?.light};
    --primary-100: ${({ theme }) => theme.colorVariations?.lighter};
    --primary-700: ${({ theme }) => theme.colorVariations?.dark};
    --primary-900: ${({ theme }) => theme.colorVariations?.darker};
    --secondary-500: ${({ theme }) => theme.secondaryColor};
    --secondary-300: ${({ theme }) => theme.secondaryColorVariations?.light};
    --secondary-100: ${({ theme }) => theme.secondaryColorVariations?.lighter};
    --secondary-700: ${({ theme }) => theme.secondaryColorVariations?.dark};
    --secondary-900: ${({ theme }) => theme.secondaryColorVariations?.darker};
    --grey-100: #F6F7F9;
    --grey-200: #E9ECEF;
    --grey-300: #DEE2E6;
    --grey-400: #CED4DA;
    --grey-500: #ADB5BD;
    --grey-600: #6C757D;
    --grey-700: #495057;
    --grey-800: #343A40;
    --grey-900: #212529;

    ${({ theme }) =>
      Object.keys(theme.primaryColorSections || {})
        .map((section) => {
          const sectionColors = theme.primaryColorSections?.[section] || {}
          return `
            --section-${section}-500: ${sectionColors.primaryColor};
            --section-${section}-300: ${sectionColors.light};
            --section-${section}-100: ${sectionColors.lighter};
            --section-${section}-700: ${sectionColors.dark};
            --section-${section}-900: ${sectionColors.darker};
          `
        })
        .join('')}


    font-size: 16px;
  }

  body {
    margin: 0;
    overflow-x: hidden;
    ${(props) => fontStyleProvider(props)};
    font-family: ${({ theme }) => theme.fontFamily.primaryFont};
    color: ${({ theme }) => theme.fontColor};
    -webkit-font-smoothing: antialiased;
    background-color: ${({ theme }) => theme.backgroundColor};
  }

  /* reset button styles */
  button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    cursor: pointer;
  }

  button::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  & svg {
    transition: 0.2s all ease-in;
  }

  & button svg:hover,
  a svg:hover {
    opacity: 0.7;
  }

  a[rel="nofollow"]:after{
    content: '';
    display: inline-block;
    width: 15px;
    height: 14px;
    background: url(${encodedExternalLinkIconBlack}) 50% 50% no-repeat;
    background-size: 100%;
    margin-left: 2px;
  }

  .modal {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    outline: 0;
  }

  ${googleExtendedAccessOverlay}
`

const getSectionPrimaryColor = (theme, hierarchyName) => {
  const { domain, primaryColor, primaryColorSections } = theme
  const hierarchyNameLowerCase = hierarchyName?.toLowerCase()
  const sectionColor = !customColorLogicSites.includes(domain)
    ? primaryColor
    : primaryColorSections?.[hierarchyNameLowerCase]?.primaryColor ||
      primaryColor
  return sectionColor
}

const getPrimaryColorBasedOffAllSections = (currentTheme, sections) => {
  let color = currentTheme.primaryColor
  let sectionsColors = currentTheme.primaryColorSections

  if (!sections) return color

  if (currentTheme.domain === 'farminglife.com') {
    // Can't use primaryColorSections as it collides with Scotsman's
    const farminglifeSectionColors = {
      'farm week': '#ce073f',
      'horse week': '#49914c',
      equestrian: '#49914c',
    }
    sectionsColors = farminglifeSectionColors
  }

  for (let i = 0; i < sections.length; i++) {
    const sectionParsed = sections[i].name.toLowerCase()
    if (sectionsColors[sectionParsed]) {
      color = sectionsColors[sectionParsed]
      break
    }
  }
  return color
}
export {
  theme,
  GlobalStyles,
  device,
  sectionPrimaryColor,
  getSectionPrimaryColor,
  getPrimaryColorBasedOffAllSections,
}
