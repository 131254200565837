export const toggleBlurred = () => {
  const readMorebutton = document.querySelector('#pap-paragraph-hide-button')
  const blurredText = document.querySelector('#blur-text')
  const remainingParas = document.querySelector('#paragraphs-after-second')

  readMorebutton?.addEventListener('click', () => {
    const isBlurredTextVisible = blurredText?.classList.toggle('show')

    readMorebutton?.classList.add('hide')

    if (isBlurredTextVisible) {
      blurredText?.classList.add('hide')
      remainingParas?.classList.add('show')
    } else {
      blurredText?.classList.remove('hide')
      remainingParas?.classList.remove('show')
    }
  })
}
