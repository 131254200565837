import { ArticleData, Configuration } from '~/types'

export enum CUSTOM_CTA_HEADER_BUTTON_IDS {
  SUBSCRIBE = 'custom-cta-header-subscribe-button',
  NEWSLETTER = 'custom-cta-header-newsletter-button',
  SHARE = 'custom-cta-header-share-button',
  COMMENT = 'custom-cta-header-comment-button',
}

export type CustomCTAHeaderScrolledProps = {
  configuration: Configuration
  article: ArticleData
}

export type CustomCTAButtonProps = {
  configuration: Configuration
  article?: ArticleData
  scrolled?: boolean
}

export type SubscribeButtonProps = {
  scrolled?: boolean
}

export type NewsletterButtonProps = {
  scrolled?: boolean
}
