import compose from 'ramda/src/compose'
import { jsLoader } from './fileLoaders'
import { shouldGetContextualVideo } from './get-contextual-video/get-contextual-video'
import { getSkyPlayer, shouldGetSkyPlayer } from './get-sky-player'
import { getPlayerId } from './get-video-player-id'
import { serialize } from './serialize'
import { DAILYMOTION_CONTEXTUAL_PLAYER_ID } from './get-contextual-video/config'
import { insertHeroImageAfterFirstParagraph } from './get-contextual-video/insert-hero-image-after-first-paragraph'
import { isDigitalPlusSubscriber } from './isDigitalPlusSubscriber'
import {
  CustomConfig,
  DailymotionPlayer,
  PlayerSettings,
} from '../types/dailymotion'
import { FIFTY_MS, TEN_SECONDS_MS } from '../constants/time'
import { getCookie } from '~/util/cookie'
import { getPermutiveTargetingData } from './permutive'
import { getCustomParams } from './gpt'
import { domainsWithMutedPlayer } from '~/config/JPIMConfig'

const NETWORK_ID = 3948
const NETWORK_PATH = `${NETWORK_ID}/VideoNetwork/`
const HERO_IMAGE_ID = 'hero-slot'
export const DAILYMOTION_ID_PREFIX = 'dm-article-'

export const isDailymotionVideoInDom = (): boolean => {
  return !!document.querySelector(`[id^=${DAILYMOTION_ID_PREFIX}]`)
}

export const isDmPlayerPresent = (): 'TRUE' | 'contextual' | 'FALSE' => {
  if (isDailymotionVideoInDom()) return 'TRUE'

  const {
    domain,
    articleType,
    sections,
    sensitive: isSensitive,
    article: { isAffiliated } = {},
  } = JSGlobals

  const isSportsArticle = window.location.pathname.startsWith('/sport')

  const willInjectContextualVideo = () => {
    return (
      !isSportsArticle &&
      shouldGetContextualVideo(
        domain,
        articleType,
        sections,
        isAffiliated ?? false,
        isSensitive,
      )
    )
  }

  return willInjectContextualVideo() ? 'contextual' : 'FALSE'
}

export const hasDailymotionVideo = (array: Array<{ id?: string }>): boolean => {
  return array.some(
    (item) =>
      typeof item.id === 'string' && item.id.startsWith(DAILYMOTION_ID_PREFIX),
  )
}

export const getLocalAdsParam = (): Record<string, any> => {
  const selectorAttr = 'data-section-dm-id'
  const dmElement = document.querySelector(`[${selectorAttr}]`)
  const videoId = dmElement?.getAttribute(selectorAttr) ?? ''
  const ppidValue = getCookie('NationalWorld_PPID') || ''

  const permValues: Record<string, string | string[]> =
    getPermutiveTargetingData() || {}
  const permAdsParam = Object.keys(permValues).reduce<Record<string, string[]>>(
    (acc, key) => {
      const value = permValues[key]
      if (value !== undefined) {
        acc[key] = Array.isArray(value)
          ? value.filter((item): item is string => item !== undefined) // Filter out undefined values
          : [value]
      }
      return acc
    },
    {},
  )

  const apData: Record<string, string | string[]> = window.apDataKeyValues || {}
  const apAdsParam = Object.keys(apData).reduce<Record<string, string[]>>(
    (acc, key) => {
      const value = apData[key]
      if (value !== undefined) {
        acc[key] = Array.isArray(value)
          ? value.filter((item): item is string => item !== undefined) // Filter out undefined values
          : [value]
      }
      return acc
    },
    {},
  )

  const jsGlobalAdsParams = Object.entries(getCustomParams(JSGlobals)).reduce<
    Record<string, string[]>
  >((acc, [key, value]) => {
    acc[key] = Array.isArray(value) ? value : [value]
    return acc
  }, {})

  const finalNONGPT: Record<string, any> = {
    ...apAdsParam,
    ...permAdsParam,
    ...jsGlobalAdsParams,
    videoSite: JSGlobals.title,
    videoID: videoId || JSGlobals.article?.DMVideoId,
    topics: (JSGlobals.article?.topics ?? []).map((topic) => topic.name),
    ppid: ppidValue, // Add PPID to the parameters
  }

  return finalNONGPT
}

const setTargetingLocals = compose(
  encodeURIComponent,
  serialize,
  getLocalAdsParam,
)

/**
 * Every time dailymotion library updates z-index to max possible value
 * (when PiP shown), change it down to a safe value.
 * When PiP is hidden, library code changes z-index to 'auto' - leave that as is
 */
const setPIPzIndex = () => {
  const dmPlayerWrappers = document.querySelectorAll(
    '.dailymotion-player-wrapper',
  )

  dmPlayerWrappers.forEach((wrapper: HTMLElement) => {
    const callback = () => {
      if (wrapper.style.zIndex !== 'auto') wrapper.style.zIndex = '2000'
    }

    const observer = new MutationObserver(callback)
    observer.observe(wrapper, { attributeFilter: ['style'] })
  })
}

const pushContextualVideoEvent = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: 'contextual video' })
}

const pushPlayerReadyEvent = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ event: 'dmPlayerReady' })
}

export const destroyAllDailymotionPlayers = async () => {
  await Promise.all(window.dailymotion.getAllPlayers())
    .then((players) => {
      players.forEach((player) => player.destroy())
    })
    .catch((e) => console.error(e))
}

export const waitForDailymotion = (
  interval: number = FIFTY_MS,
  timeout: number = TEN_SECONDS_MS,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const startTime = Date.now()
    const checkInterval = setInterval(() => {
      if (window.dailymotion) {
        clearInterval(checkInterval)
        resolve()
      } else if (Date.now() - startTime >= timeout) {
        clearInterval(checkInterval)
        reject(new Error('Timed out waiting for Dailymotion SDK'))
      }
    }, interval)
  })
}

export const pauseAllDailymotionPlayers = async () => {
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms))

  try {
    if (!window.dailymotion) {
      await waitForDailymotion() // Ensure the Dailymotion SDK is available
    }

    await delay(5000) // Delay for 5 seconds

    // Get the array of Promises for all players
    const playerPromises = await window.dailymotion.getAllPlayers()

    // Await the resolution of all player Promises
    const players = await Promise.all(playerPromises)

    // Map each player to a new Promise that resolves when the player is in the 'playing' state
    const playPromises = players.map((player) => {
      return player
        .getState()
        .then((state: { playerIsPlaying: boolean }) => {
          if (state.playerIsPlaying) {
            return player
          } else {
            return null // Player is not playing
          }
        })
        .catch((error: Error) => {
          console.error('Error getting player state:', error)
          return null
        })
    })

    // Filter out null values (players that are not playing)
    const playingPlayers = (await Promise.all(playPromises)).filter(
      (player) => player !== null,
    )

    // Pause all players that are in the 'playing' state
    const pausePromises = playingPlayers.map((player) => {
      return player.pause().catch((error: Error) => {
        console.error('Error pausing player:', error)
      })
    })
    // Wait for all players to be paused
    await Promise.all(pausePromises)
  } catch (e) {
    //no op
  }
}

const delayedPIPClose = () => {
  if (!['lep.co.uk', 'peterboroughtoday.co.uk'].includes(JSGlobals.domain)) {
    return
  }
  if (!window.matchMedia('(max-width: 767px)').matches) {
    return
  }

  const checkScrollDepth = () => {
    if (window.scrollY > 678) {
      setTimeout(() => {
        window.dailymotion.pipClose()
      }, TEN_SECONDS_MS)
      window.removeEventListener('scroll', checkScrollDepth)
    }
  }

  window.addEventListener('scroll', checkScrollDepth)
}

export const getCustomConfigLocals = (
  networkId: string,
  isPremiumArticle: boolean = false,
): CustomConfig => {
  const ppidValue = getCookie('NationalWorld_PPID') || '' // Retrieve PPID value

  return {
    dynamiciu: `/${networkId}`, // '/' added at request from Dailymotion
    keyvalues: setTargetingLocals(),
    plcmt: '1', // all players '1'
    ppid: ppidValue, // Add the required ppid property
    ...((isPremiumArticle || isDigitalPlusSubscriber()) && {
      premium: 'true',
    }),
  }
}

let retryCount = 0
const MAX_RETRIES = 5

/**
 * Initializes the Dailymotion player with the given settings and handles heavy ads intervention.
 * @param {string} playerContainerId - The ID of the container where the player will be created.
 * @param {Object} playerSettings - Configuration settings for the Dailymotion player.
 * @returns {Promise} - Resolves with the player instance if successful, otherwise rejects with an error.
 */
const initializeDailymotionPlayer = (
  playerContainerId: string,
  playerSettings: PlayerSettings,
  isContextualEmbed: boolean = false,
): Promise<DailymotionPlayer> => {
  return new Promise((resolve, reject) => {
    if (window.dailymotion) {
      window.dailymotion
        .createPlayer(playerContainerId, playerSettings)
        .then((player: DailymotionPlayer) => {
          // Reset the retry count upon successful player initialization
          retryCount = 0

          // Add an event listener for the heavy ads intervention event
          player.on(
            window.dailymotion.events.PLAYER_HEAVYADSINTERVENTION,
            ({
              playerHeavyAdsInterventionReports,
            }: {
              playerHeavyAdsInterventionReports: any
            }) => {
              console.log(
                'PLAYER_HEAVYADSINTERVENTION',
                playerHeavyAdsInterventionReports,
              )

              // Check if the retry count has reached its maximum limit
              if (retryCount < MAX_RETRIES) {
                retryCount++ // Increment the retry count

                // Destroy the current player to prepare for reinitialization
                player
                  .destroy()
                  .then(() => {
                    // Modify player settings to disable autoplay to prevent further heavy ads intervention
                    const newPlayerSettings = {
                      ...playerSettings,
                      params: {
                        ...playerSettings.params,
                        autoplay: false,
                      },
                    }

                    // Recursively reinitialize the player with the updated settings
                    initializeDailymotionPlayer(
                      playerContainerId,
                      newPlayerSettings,
                    ).catch((error) =>
                      console.error('Error reinitializing player:', error),
                    )
                  })
                  .catch((destroyError) => {
                    console.error('Error destroying player:', destroyError)
                  })
              } else {
                // Log an error message if the maximum number of retries has been reached
                console.error(
                  'Maximum retries reached. Player will not be reinitialized.',
                )
              }
            },
          )

          setPIPzIndex()

          if (isContextualEmbed) pushContextualVideoEvent()
          pushPlayerReadyEvent()

          delayedPIPClose()

          resolve(player)
        })
        .catch((error: Error) => {
          console.error('Error with Dailymotion Player:', error)
          reject(error)
        })
    } else {
      reject(new Error('Dailymotion SDK not loaded'))
    }
  })
}

export const dailymotionArticle = () => {
  const {
    domain,
    articleType,
    sections,
    sensitive: isSensitive,
    article: { isAffiliated } = {},
    pageType,
  } = JSGlobals
  const selectorAttr = 'data-article-dm-id'
  const dmElements = Array.from(
    document.querySelectorAll(`[${selectorAttr}]`),
  ) as HTMLElement[]
  const heroImage = document.getElementById(HERO_IMAGE_ID)
  const isArticleTypeVideo = JSGlobals.article?.isArticleTypeVideo ?? false
  const isSportsArticle = window.location.pathname.startsWith('/sport')
  const isProductArticle = pageType === 'affiliate article'
  const isPremiumArticle =
    pageType === 'sponsored article' || articleType === 'sponsored article'

  if (heroImage) {
    dmElements.unshift(heroImage) // Add hero image to the beginning of the array
  }

  // Hero SkyPlayer integration, on Sports articles
  if (shouldGetSkyPlayer(JSGlobals.article ?? {}, JSGlobals.domain)) {
    getSkyPlayer()
  }

  const isContextualVideoArticle = () => {
    // Hero Dailymotion contextual-video integration to replace hero image, NOT on Sports articles
    return (
      !isSportsArticle &&
      shouldGetContextualVideo(
        domain,
        articleType,
        sections,
        isAffiliated ?? false,
        isSensitive,
        isProductArticle,
      )
    )
  }

  // Early return if article does not have a hero video, SkyPlayer, or Contextual Videos
  if (!hasDailymotionVideo(dmElements) && !isContextualVideoArticle()) {
    return
  }

  // Check if the current page is the first page of the article
  const isFirstPage =
    window.location.search.includes('page=1') ||
    !window.location.search.includes('page=')

  // Insert hero image in article body for contextual embeds
  if (isContextualVideoArticle() && heroImage) {
    insertHeroImageAfterFirstParagraph(dmElements[0], isFirstPage)
  }

  if (!isFirstPage) {
    return
  }

  jsLoader(
    [
      `https://geo.dailymotion.com/libs/player/${getPlayerId(
        !!heroImage && isContextualVideoArticle(), // Different Player ID for contextual-video integration to replace hero image
        isArticleTypeVideo,
      )}.js`,
    ],
    'dailymotion',
  ).then(() => {
    dmElements.forEach(async (el) => {
      const networkId = NETWORK_PATH
      const dmId = el.getAttribute(selectorAttr)
      const isContextualEmbed =
        el.id === HERO_IMAGE_ID && isContextualVideoArticle()

      const customConfig = getCustomConfigLocals(networkId, isPremiumArticle) // Direct synchronous call

      const playerSettings: PlayerSettings = {
        video: isContextualEmbed ? '' : dmId,
        player:
          // Pass Player ID as a runtime parameter for subsequent players
          // https://developers.dailymotion.com/guides/getting-started-with-web-sdk/#player-library-script-multiple-players
          isContextualEmbed
            ? DAILYMOTION_CONTEXTUAL_PLAYER_ID
            : getPlayerId(false, isArticleTypeVideo),
        referrerPolicy: 'no-referrer-when-downgrade',
        params: {
          customConfig, // Assign the resolved object
        },
      }

      if (domainsWithMutedPlayer.includes(domain)) {
        delete playerSettings.params.mute
      } else {
        playerSettings.params.mute = !isArticleTypeVideo // turns sound off if article type is not video
      }

      const playerContainerId =
        el.id === HERO_IMAGE_ID && isContextualVideoArticle()
          ? HERO_IMAGE_ID
          : `${DAILYMOTION_ID_PREFIX}${dmId}`

      initializeDailymotionPlayer(
        playerContainerId,
        playerSettings,
        isContextualEmbed,
      ).catch((error) => {
        console.error('Error with Dailymotion Player:', error)
      })
    })
  })
}

export const dailymotionSection = () => {
  const dmVideoPreviewElements = document.querySelectorAll(
    '[data-type="dm-video-preview"]',
  )
  if (dmVideoPreviewElements.length) {
    return jsLoader(['https://api.dmcdn.net/widget.js'], 'dailymotion')
  }

  const selectorAttr = 'data-section-dm-id'
  const dmElements = document.querySelectorAll(`[${selectorAttr}]`)
  if (dmElements.length) {
    return jsLoader(
      ['https://geo.dailymotion.com/libs/player/x4uxe.js'],
      'dailymotion',
    ).then(() => {
      dmElements.forEach(async (el) => {
        const video = el.getAttribute(selectorAttr)
        const playerSettings: PlayerSettings = {
          referrerPolicy: 'no-referrer-when-downgrade',
          params: {
            mute: false, // Home pages
            customConfig: await getCustomConfigLocals(NETWORK_PATH),
          },
        }

        const playerType = el.getAttribute('data-video-type')

        if (playerType === 'video') {
          playerSettings.video = video
        } else {
          playerSettings.playlist = video
        }

        initializeDailymotionPlayer(
          `dm-section-${video}`,
          playerSettings,
        ).catch((error) => {
          console.error('Error with Dailymotion Player:', error)
        })
      })
    })
  }
}

export const initDailyMotion = () => {
  if (JSGlobals.article) {
    return dailymotionArticle()
  }
  return dailymotionSection()
}
