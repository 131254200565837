import PianoArticlePaywall from '~/library/ThirdParty/PianoArticlePaywall'
import { ArticleData } from '~/types'
import isAxateEnabled from '~/util/isAxateEnabled'

type RenderPianoPaywallProps = {
  article: ArticleData
  content: Array<JSX.Element>
  contentArray: {
    type: string
    data: {
      markup: string
    }
  }[]
  domain: string
  isPremium: boolean
  piano: boolean
}

const renderPianoPaywall = ({
  content,
  contentArray,
  domain,
  isPremium,
  piano,
}: RenderPianoPaywallProps) => {
  if (!piano) {
    return content
  }

  // premium articles on Axate Domains deal with Paywalls in the piano-paywall-bottom div
  // non-premium articles however still need piano-article-paywall injected for metering etc.
  // e.g. "You have read 3 articles this month, please subscribe to continue reading"
  if (isAxateEnabled(domain) && isPremium) {
    return content
  }

  const clonedContent = [...content]

  clonedContent.splice(
    contentArray.length >= 5 ? 3 : 1,
    0,
    <PianoArticlePaywall key="piano-article-paywall" />,
  )

  const taboolaIndex = clonedContent.findIndex(
    (content) => content.props?.id === 'taboola-mid-0',
  )

  const doesTaboolaExist = taboolaIndex !== -1

  if (!doesTaboolaExist) {
    return clonedContent
  }

  // Inject Mid Piano paywall after Taboola
  clonedContent.splice(
    taboolaIndex + 1,
    0,
    <PianoArticlePaywall
      key="piano-article-paywall-mid"
      className="piano-article-paywall-mid"
    />,
  )

  return clonedContent
}

export default renderPianoPaywall
