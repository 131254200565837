export const getSiteSections = (arr: string[][]): string[] => {
  // Flatten the array
  const flattenedArr = arr.flat()
  // Remove empty strings
  const filteredArr = flattenedArr.filter((a) => a !== '')
  // Get unique values
  const uniqueArr = [...new Set(filteredArr)]

  return uniqueArr
}
