export const toggleHeroCaption = (heroEl?: HTMLElement) => {
  const context = heroEl || document

  const infoButton = context.querySelector('.hero-img .caption-info-button')
  const overlay = context.querySelector('.hero-img .caption-overlay')

  infoButton?.addEventListener('click', () => {
    const isOverlayVisible = overlay?.classList.toggle('show')
    overlay?.setAttribute('aria-hidden', (!isOverlayVisible).toString())
  })
}
