import { ArticleData, FC } from '~/types'
import Subheadline from './Subheadline'

type ArticleContentProps = {
  isPremium: boolean
  article: ArticleData
  strippedLeadText: string
  content: JSX.Element[]
}

export const ArticleContent: FC<ArticleContentProps> = ({
  isPremium,
  article,
  strippedLeadText,
  content,
}) => {
  return (
    <>
      {!isPremium && (
        <Subheadline
          id={article.polar && 'native-content-summary'}
          dangerouslySetInnerHTML={{ __html: strippedLeadText }}
          isPremiumArticleLayout={isPremium}
          className="below-image article-standfirst"
          data-testid="article-standfirst"
        />
      )}
      {content}
    </>
  )
}
