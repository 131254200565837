import { jsLoader } from '~/util/fileLoaders'

export const VISIBLE_CLASS = 'visible'
export const SUBMITTED_CLASS = 'submitted'

export const newsletterSignupForm = () => {
  const { recaptchaSiteKey } = window.JSGlobals ?? {}

  const forms = document.querySelectorAll<HTMLFormElement>(
    '[id$="newsletter-signup-form"]',
  )

  forms.forEach((form) => {
    const emailInput = form.querySelector<HTMLInputElement>(
      'input[type="email"]',
    )

    emailInput?.addEventListener('input', async () => {
      if (!window.grecaptcha) {
        await jsLoader(
          [
            `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`,
          ],
          'recaptcha for newsletter signup',
        )
      }
      validateInput(emailInput)
    })

    form.addEventListener('submit', async (e) => {
      e.preventDefault()
      toggleFormMessage(form.id, 'submitting', 'show')

      const formData = new FormData(form)

      const token = await window.grecaptcha?.execute(recaptchaSiteKey, {
        action: 'submit',
      })

      formData.set('recaptcha_token', token)

      submitForm(form, formData)
    })
  })
}

const submitForm = async (form: HTMLFormElement, formData: FormData) => {
  const res = await fetch('/internal-api/newsletter', {
    method: 'POST',
    body: formData,
  })

  toggleFormMessage(form.id, 'submitting', 'hide')

  if (res.status === 200) {
    form.classList.add(SUBMITTED_CLASS)
    form.reset()
    toggleFormMessage(form.id, 'success', 'show')
    sendTracking(form.id, 'success')
    window._cbq?.push(['trackNewsletterSignupComplete'])
  } else {
    toggleFormMessage(form.id, 'error', 'show')
    sendTracking(form.id, 'error')
  }
}

const validateInput = (input: HTMLInputElement) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const submitBtn = input.form?.querySelector<HTMLButtonElement>(
    'button[type="submit"]',
  )

  const fieldValue = input.value.trim()

  if (fieldValue != '' && emailRegex.test(fieldValue)) {
    submitBtn?.removeAttribute('disabled')
  }
}

const toggleFormMessage = (
  formId: string,
  message: 'success' | 'error' | 'submitting',
  state: 'show' | 'hide',
) => {
  const messageElement = document.getElementById(
    `newsletter-signup-${formId}-${message}`,
  )

  if (!messageElement) return
  if (state === 'show') return messageElement.classList.add(VISIBLE_CLASS)
  if (state === 'hide') return messageElement.classList.remove(VISIBLE_CLASS)
}

const sendTracking = (formId: string, action: 'success' | 'error') => {
  const data = {
    event: 'gaEvent',
    eventCategory: 'newsletter',
    eventAction: action,
    eventLabel: formId,
  }
  window.dataLayer?.push(data)
}
