import { jsLoader } from './fileLoaders'

export const initTaboolaTag = (pageType: string) => {
  window._taboola = window._taboola || []
  window._taboola.push({ [pageType]: 'auto' })

  jsLoader(
    [
      {
        src: 'https://cdn.taboola.com/libtrc/nationalworld-network/loader.js',
        id: 'tb_loader_script',
      },
    ],
    'taboola',
  )
    .then(() => {
      if (
        typeof window.performance !== 'undefined' &&
        window.performance.mark
      ) {
        window.performance.mark('tbl_ic')
      }

      const firstScript = document.getElementsByTagName('script')[0]

      if (firstScript) {
        ;(function (
          e: HTMLScriptElement,
          f: HTMLScriptElement,
          u: string,
          i: string,
        ) {
          if (!document.getElementById(i)) {
            e.async = true
            e.src = u
            e.id = i
            f.parentNode?.insertBefore(e, f)
          }
        })(
          document.createElement('script'),
          firstScript,
          'https://cdn.taboola.com/libtrc/nationalworld-network/loader.js',
          'tb_loader_script',
        )
      }
    })
    .catch((error) => {
      console.error('Error loading Taboola script:', error)
    })
}

export const injectTaboolaFlushScript = () => {
  if (typeof window._taboola === 'undefined') {
    console.warn('Taboola is not initialized. Skipping flush.')
    return
  }

  const scriptElement = document.createElement('script')
  scriptElement.type = 'text/javascript'
  scriptElement.innerHTML = `
    window._taboola = window._taboola || [];
    _taboola.push({flush: true});
  `
  document.body.appendChild(scriptElement)
}
