import { Domain } from '~/types'

export const DAILYMOTION_CONTEXTUAL_PLAYER_ID = 'xxsnk'
export const BLACKLISTED_SECTIONS: string[] = ['Crime', 'Troubles', 'Must Read']

export const BLACKLISTED_ARTICLE_TYPES: string[] = [
  'affiliate article',
  'sponsored article',
]

export const BLACKLISTED_DOMAINS: Domain[] = [
  '3addedminutes.com',
  'totallysnookered.com',
]
